import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import multiguard from "vue-router-multiguard";
Vue.use(VueRouter);

// Logic for login gard for routes
const loggedIn = function (to, from, next) {
  if (!store.state.auth.data.token) {
    next({
      name: "PageAuthSignin",
    });
  } else {
    next();
  }
};

const loggedOut = function (to, from, next) {
  if (store.state.auth.data.token) {
    next({
      name: "PageDashboard",
    });
  } else {
    next();
  }
};

// all route logics
const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () =>
      import(/* webpackChunkName: "layout-main" */ "@/layouts/LayoutApp.vue"),
    beforeEnter: multiguard([loggedIn]),
    children: [
      {
        path: "/dashboard",
        name: "PageDashboard",
        component: () =>
          import(
            /* webpackChunkName: "page-PageDashboard" */ "@/views/home/PageDashboard.vue"
          ),
        meta: {
          title: "Dashboard",
          breadcrumbsItems: [
            {
              text: "Dashboard",
              disabled: true,
            },
          ],
        },
      },
      {
        path: "/staff",
        name: "PageStaffList",
        component: () =>
          import(
            /* webpackChunkName: "page-staff-list" */ "@/views/staff/PageStaffList.vue"
          ),
        meta: {
          title: "Staff",
          breadcrumbsItems: [
            {
              text: "Dashboard",
              disabled: false,
              to: { name: "PageDashboard" },
            },
            {
              text: "Staff",
              disabled: true,
            },
          ],
        },
      },
      {
        path: "/staff/new",
        name: "PageNewStaff",
        component: () =>
          import(
            /* webpackChunkName: "page-new-staff" */ "@/views/staff/PageNewStaff.vue"
          ),
        meta: {
          title: "Staff",
          breadcrumbsItems: [
            {
              text: "Dashboard",
              disabled: false,
              to: { name: "PageDashboard" },
            },
            {
              text: "Staff",
              disabled: false,
              to: { name: "PageStaffList" },
            },
            {
              text: "New",
              disabled: true,
            },
          ],
        },
      },
      {
        path: "/staff/:uuid",
        name: "PageViewStaff",
        component: () =>
          import(
            /* webpackChunkName: "page-view-staff" */ "@/views/staff/PageViewStaff.vue"
          ),
        meta: {
          title: "Staff",
          breadcrumbsItems: [
            {
              text: "Dashboard",
              disabled: false,
              to: { name: "PageDashboard" },
            },
            {
              text: "Staff",
              disabled: false,
              to: { name: "PageStaffList" },
            },
            {
              text: "New",
              disabled: true,
            },
          ],
        },
        beforeEnter: (to, from, next) => {
          to.meta.breadcrumbsItems[2].text = to.query.bc;
          next();
        },
      },
      {
        path: "/staff/:uuid/edit",
        name: "PageEditStaff",
        component: () =>
          import(
            /* webpackChunkName: "page-edit-staff" */ "@/views/staff/PageEditStaff.vue"
          ),
        meta: {
          title: "Staff",
          breadcrumbsItems: [
            {
              text: "Dashboard",
              disabled: false,
              to: { name: "PageDashboard" },
            },
            {
              text: "Staff",
              disabled: false,
              to: { name: "PageStaffList" },
            },
            {
              text: "Edit",
              disabled: true,
            },
          ],
        },
        beforeEnter: (to, from, next) => {
          to.meta.breadcrumbsItems[2].text = to.query.bc;
          next();
        },
      },

      // Roles
      {
        path: "/roles",
        name: "PageRoleList",
        component: () =>
          import(
            /* webpackChunkName: "page-roles-list" */ "@/views/roles/PageRoleList.vue"
          ),
        meta: {
          title: "Roles",
          breadcrumbsItems: [
            {
              text: "Dashboard",
              disabled: false,
              to: { name: "PageDashboard" },
            },
            {
              text: "Roles",
              disabled: true,
            },
          ],
        },
      },
      {
        path: "/roles/new",
        name: "PageNewRole",
        component: () =>
          import(
            /* webpackChunkName: "page-new-role" */ "@/views/roles/PageNewRole.vue"
          ),
        meta: {
          title: "Roles",
          breadcrumbsItems: [
            {
              text: "Dashboard",
              disabled: false,
              to: { name: "PageDashboard" },
            },
            {
              text: "Roles",
              disabled: false,
              to: { name: "PageRoleList" },
            },
            {
              text: "New",
              disabled: true,
            },
          ],
        },
      },
      {
        path: "/roles/:uuid",
        name: "PageViewRole",
        component: () =>
          import(
            /* webpackChunkName: "page-view-role" */ "@/views/roles/PageViewRole.vue"
          ),
        meta: {
          title: "Roles",
          breadcrumbsItems: [
            {
              text: "Dashboard",
              disabled: false,
              to: { name: "PageDashboard" },
            },
            {
              text: "Roles",
              disabled: false,
              to: { name: "PageRoleList" },
            },
            {
              text: "View",
              disabled: true,
            },
          ],
        },
        beforeEnter: (to, from, next) => {
          to.meta.breadcrumbsItems[2].text = to.query.bc;
          next();
        },
      },
      {
        path: "/roles/:uuid/edit",
        name: "PageEditRole",
        component: () =>
          import(
            /* webpackChunkName: "page-edit-role" */ "@/views/roles/PageEditRole.vue"
          ),
        meta: {
          title: "Roles",
          breadcrumbsItems: [
            {
              text: "Dashboard",
              disabled: false,
              to: { name: "PageDashboard" },
            },
            {
              text: "Roles",
              disabled: false,
              to: { name: "PageRoleList" },
            },
            {
              text: "Edit",
              disabled: true,
            },
          ],
        },
        beforeEnter: (to, from, next) => {
          to.meta.breadcrumbsItems[2].text = to.query.bc;
          next();
        },
      },

      // Vehicles 
      {
        path: "/vehicles",
        name: "PageVehicleList",
        component: () =>
          import(
            /* webpackChunkName: "page-vehicles-list" */ "@/views/vehicles/PageVehicleList.vue"
          ),
        meta: {
          title: "Vehicles",
          breadcrumbsItems: [
            {
              text: "Dashboard",
              disabled: false,
              to: { name: "PageDashboard" },
            },
            {
              text: "Vehicles",
              disabled: true,
            },
          ],
        },
      },
      {
        path: "/vehicles/new",
        name: "PageNewVehicle",
        component: () =>
          import(
            /* webpackChunkName: "page-new-vehicle" */ "@/views/vehicles/PageNewVehicle.vue"
          ),
        meta: {
          title: "Vehicles",
          breadcrumbsItems: [
            {
              text: "Dashboard",
              disabled: false,
              to: { name: "PageDashboard" },
            },
            {
              text: "Vehicles",
              disabled: false,
              to: { name: "PageVehicleList" },
            },
            {
              text: "New",
              disabled: true,
            },
          ],
        },
      },
      {
        path: "/vehicles/:uuid",
        name: "PageViewVehicle",
        component: () =>
          import(
            /* webpackChunkName: "page-view-vehicle" */ "@/views/vehicles/PageViewVehicle.vue"
          ),
        meta: {
          title: "Vehicles",
          breadcrumbsItems: [
            {
              text: "Dashboard",
              disabled: false,
              to: { name: "PageDashboard" },
            },
            {
              text: "Vehicles",
              disabled: false,
              to: { name: "PageVehicleList" },
            },
            {
              text: "View",
              disabled: true,
            },
          ],
        },
        beforeEnter: (to, from, next) => {
          to.meta.breadcrumbsItems[2].text = to.query.bc;
          next();
        },
      },
      {
        path: "/vehicles/:uuid/edit",
        name: "PageEditVehicle",
        component: () =>
          import(
            /* webpackChunkName: "page-edit-vehicle" */ "@/views/vehicles/PageEditVehicle.vue"
          ),
        meta: {
          title: "Vehicles",
          breadcrumbsItems: [
            {
              text: "Dashboard",
              disabled: false,
              to: { name: "PageDashboard" },
            },
            {
              text: "Vehicles",
              disabled: false,
              to: { name: "PageVehicleList" },
            },
            {
              text: "Edit",
              disabled: true,
            },
          ],
        },
        beforeEnter: (to, from, next) => {
          to.meta.breadcrumbsItems[2].text = to.query.bc;
          next();
        },
      },

      // Projects 
      {
        path: "/projects",
        name: "PageProjectList",
        component: () =>
          import(
            /* webpackChunkName: "page-projects-list" */ "@/views/projects/PageProjectList.vue"
          ),
        meta: {
          title: "Projects",
          breadcrumbsItems: [
            {
              text: "Dashboard",
              disabled: false,
              to: { name: "PageDashboard" },
            },
            {
              text: "Projects",
              disabled: true,
            },
          ],
        },
      },
      {
        path: "/projects/new",
        name: "PageNewProject",
        component: () =>
          import(
            /* webpackChunkName: "page-new-project" */ "@/views/projects/PageNewProject.vue"
          ),
        meta: {
          title: "Projects",
          breadcrumbsItems: [
            {
              text: "Dashboard",
              disabled: false,
              to: { name: "PageDashboard" },
            },
            {
              text: "Projects",
              disabled: false,
              to: { name: "PageProjectList" },
            },
            {
              text: "New",
              disabled: true,
            },
          ],
        },
      },
      {
        path: "/projects/:uuid",
        name: "PageViewProject",
        component: () =>
          import(
            /* webpackChunkName: "page-view-project" */ "@/views/projects/PageViewProject.vue"
          ),
        meta: {
          title: "Projects",
          breadcrumbsItems: [
            {
              text: "Dashboard",
              disabled: false,
              to: { name: "PageDashboard" },
            },
            {
              text: "Projects",
              disabled: false,
              to: { name: "PageProjectList" },
            },
            {
              text: "View",
              disabled: true,
            },
          ],
        },
        beforeEnter: (to, from, next) => {
          to.meta.breadcrumbsItems[2].text = to.query.bc;
          next();
        },
      },
      {
        path: "/projects/:uuid/edit",
        name: "PageEditProject",
        component: () =>
          import(
            /* webpackChunkName: "page-edit-project" */ "@/views/projects/PageEditProject.vue"
          ),
        meta: {
          title: "Projects",
          breadcrumbsItems: [
            {
              text: "Dashboard",
              disabled: false,
              to: { name: "PageDashboard" },
            },
            {
              text: "Projects",
              disabled: false,
              to: { name: "PageProjectList" },
            },
            {
              text: "Edit",
              disabled: true,
            },
          ],
        },
        beforeEnter: (to, from, next) => {
          to.meta.breadcrumbsItems[2].text = to.query.bc;
          next();
        },
      },

      // Vehicle Running Log
      {
        path: "/logs/vehicle-running-logs",
        name: "PageMyVehicleRunningList",
        component: () =>
          import(
            /* webpackChunkName: "page-vehicle-running-log-list" */ "@/views/logs/vehicleRunning/PageMyVehicleRunningList.vue"
          ),
        meta: {
          title: "Logs",
          breadcrumbsItems: [
            {
              text: "Dashboard",
              disabled: false,
              to: { name: "PageDashboard" },
            },
            {
              text: "Logs",
              disabled: true,
            },
            {
              text: "Vehicle Running Logs",
              disabled: true,
            },
          ],
        },
      },
      {
        path: "/logs/vehicle-running-logs/all",
        name: "PageAllVehicleRunningList",
        component: () =>
          import(
            /* webpackChunkName: "page-all-vehicle-running-log-list" */ "@/views/logs/vehicleRunning/PageAllVehicleRunningList.vue"
          ),
        meta: {
          title: "Logs",
          breadcrumbsItems: [
            {
              text: "Dashboard",
              disabled: false,
              to: { name: "PageDashboard" },
            },
            {
              text: "Logs",
              disabled: true,
            },
            {
              text: "Vehicle Running Logs",
              disabled: true,
            },
          ],
        },
      },
      {
        path: "/logs/vehicle-running-logs/new",
        name: "PageNewVehicleRunning",
        component: () =>
          import(
            /* webpackChunkName: "page-new-vehicle-running-log" */ "@/views/logs/vehicleRunning/PageNewVehicleRunning.vue"
          ),
        meta: {
          title: "Logs",
          breadcrumbsItems: [
            {
              text: "Dashboard",
              disabled: false,
              to: { name: "PageDashboard" },
            },
            {
              text: "Logs",
              disabled: true,
            },
            {
              text: "Vehicle Running Logs",
              disabled: false,
              to: { name: "PageMyVehicleRunningList" },
            },
            {
              text: "New",
              disabled: true,
            },
          ],
        },
      },
      {
        path: "/logs/vehicle-running-logs/:uuid",
        name: "PageViewVehicleRunning",
        component: () =>
          import(
            /* webpackChunkName: "page-view-vehicle-running-log" */ "@/views/logs/vehicleRunning/PageViewVehicleRunning.vue"
          ),
        meta: {
          title: "Logs",
          breadcrumbsItems: [
            {
              text: "Dashboard",
              disabled: false,
              to: { name: "PageDashboard" },
            },
            {
              text: "Logs",
              disabled: true,
            },
            {
              text: "Vehicle Running Logs",
              disabled: false,
              to: { name: "PageMyVehicleRunningList" },
            },
            {
              text: "View",
              disabled: true,
            },
          ],
        },
        beforeEnter: (to, from, next) => {
          to.meta.breadcrumbsItems[3].text = to.query.bc;
          next();
        },
      },
      {
        path: "/logs/vehicle-running-logs/:uuid/edit",
        name: "PageEditVehicleRunning",
        component: () =>
          import(
            /* webpackChunkName: "page-edit-vehicle-running-log" */ "@/views/logs/vehicleRunning/PageEditVehicleRunning.vue"
          ),
        meta: {
          title: "Logs",
          breadcrumbsItems: [
            {
              text: "Dashboard",
              disabled: false,
              to: { name: "PageDashboard" },
            },
            {
              text: "Logs",
              disabled: true,
            },
            {
              text: "Vehicle Running Logs",
              disabled: false,
              to: { name: "PageMyVehicleRunningList" },
            },
            {
              text: "Edit",
              disabled: true,
            },
          ],
        },
        beforeEnter: (to, from, next) => {
          to.meta.breadcrumbsItems[3].text = to.query.bc;
          next();
        },
      },
      

      // Vehicle Fuel Log
      {
        path: "/logs/vehicle-fuel",
        name: "PageMyVehicleFuelList",
        component: () =>
          import(
            /* webpackChunkName: "page-vehicle-fuel-list" */ "@/views/logs/vehicleFuel/PageMyVehicleFuelList.vue"
          ),
        meta: {
          title: "Logs",
          breadcrumbsItems: [
            {
              text: "Dashboard",
              disabled: false,
              to: { name: "PageDashboard" },
            },
            {
              text: "Logs",
              disabled: true,
            },
            {
              text: "Vehicle Fuel",
              disabled: true,
            },
          ],
        },
      },
      {
        path: "/logs/vehicle-fuel/all",
        name: "PageAllVehicleFuelList",
        component: () =>
          import(
            /* webpackChunkName: "page-all-vehicle-fuel-list" */ "@/views/logs/vehicleFuel/PageAllVehicleFuelList.vue"
          ),
        meta: {
          title: "Logs",
          breadcrumbsItems: [
            {
              text: "Dashboard",
              disabled: false,
              to: { name: "PageDashboard" },
            },
            {
              text: "Logs",
              disabled: true,
            },
            {
              text: "Vehicle Fuel",
              disabled: true,
            },
          ],
        },
      },

      // Vehicle Repair Log
      {
        path: "/logs/vehicle-repairs",
        name: "PageMyVehicleRepairList",
        component: () =>
          import(
            /* webpackChunkName: "page-vehicle-repairs-list" */ "@/views/logs/vehicleRepairs/PageMyVehicleRepairList.vue"
          ),
        meta: {
          title: "Logs",
          breadcrumbsItems: [
            {
              text: "Dashboard",
              disabled: false,
              to: { name: "PageDashboard" },
            },
            {
              text: "Logs",
              disabled: true,
            },
            {
              text: "Vehicle Repairs",
              disabled: true,
            },
          ],
        },
      },
      {
        path: "/logs/vehicle-repairs/all",
        name: "PageAllVehicleRepairList",
        component: () =>
          import(
            /* webpackChunkName: "page-all-vehicle-repairs-list" */ "@/views/logs/vehicleRepairs/PageAllVehicleRepairList.vue"
          ),
        meta: {
          title: "Logs",
          breadcrumbsItems: [
            {
              text: "Dashboard",
              disabled: false,
              to: { name: "PageDashboard" },
            },
            {
              text: "Logs",
              disabled: true,
            },
            {
              text: "Vehicle Repairs",
              disabled: true,
            },
          ],
        },
      },

      // reports
      {
        path: "/reports/mpp",
        name: "PageReportMPP",
        component: () =>
          import(
            /* webpackChunkName: "page-reports-mpp" */ "@/views/reports/PageReportMPP.vue"
          ),
        meta: {
          title: "Reports",
          breadcrumbsItems: [
            {
              text: "Dashboard",
              disabled: false,
              to: { name: "PageDashboard" },
            },
            {
              text: "Reports",
              disabled: true,
            },
            {
              text: "Mileage/Project Report",
              disabled: true,
            },
          ],
        },
      },
      {
        path: "/reports/pum",
        name: "PageReportPUM",
        component: () =>
          import(
            /* webpackChunkName: "page-reports-pum" */ "@/views/reports/PageReportPUM.vue"
          ),
        meta: {
          title: "Reports",
          breadcrumbsItems: [
            {
              text: "Dashboard",
              disabled: false,
              to: { name: "PageDashboard" },
            },
            {
              text: "Reports",
              disabled: true,
            },
            {
              text: "Private Use Report",
              disabled: true,
            },
          ],
        },
      },
      {
        path: "/reports/pvm",
        name: "PageReportPVM",
        component: () =>
          import(
            /* webpackChunkName: "page-reports-pvm" */ "@/views/reports/PageReportPVM.vue"
          ),
        meta: {
          title: "Reports",
          breadcrumbsItems: [
            {
              text: "Dashboard",
              disabled: false,
              to: { name: "PageDashboard" },
            },
            {
              text: "Reports",
              disabled: true,
            },
            {
              text: "Mileage/Project Pvt. Report",
              disabled: true,
            },
          ],
        },
      },
    ],
  },

  // Auth Pages
  {
    path: "/auth",
    redirect: "/auth/signin",
    component: () =>
      import(/* webpackChunkName: "layout-main" */ "@/layouts/LayoutMain.vue"),
    beforeEnter: multiguard([loggedOut]),
    children: [
      {
        path: "signin",
        name: "PageAuthSignin",
        component: () =>
          import(
            /* webpackChunkName: "auth-signin" */ "@/views/auth/Signin.vue"
          ),
        meta: {
          title: "Signin",
        },
      },
      {
        path: "otp",
        name: "PageAuthOTP",
        component: () =>
          import(/* webpackChunkName: "auth-otp" */ "@/views/auth/OTP.vue"),
        meta: {
          title: "OTP",
        },
      },
    ],
  },

  // Error Pages
  {
    path: "",
    component: () =>
      import(/* webpackChunkName: "layout-main" */ "@/layouts/LayoutMain.vue"),
    children: [
      {
        path: "error/expired",
        name: "PageErrorSessionExpired",
        component: () =>
          import(
            /* webpackChunkName: "main-expaired" */ "@/views/error/PageErrorSessionExpired.vue"
          ),
      },
      {
        path: "error/something-went-wrong",
        name: "PageErrorWentWrong",
        component: () =>
          import(
            /* webpackChunkName: "main-something-went-wrong" */ "@/views/error/PageErrorWentWrong.vue"
          ),
      },
      {
        path: "error/not-authorized",
        name: "PageErrorNotAuthorized",
        component: () =>
          import(
            /* webpackChunkName: "main-not-authorized" */ "@/views/error/PageErrorNotAuthorized.vue"
          ),
      },
      {
        path: "/:catchAll(.*)",
        name: "PageErrorNotFound",
        component: () =>
          import(
            /* webpackChunkName: "main-not-found" */ "@/views/error/PageErrorNotFound.vue"
          ),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

//page title
router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title ? "IMSO | " + to.meta.title : "IMSO";
  });
});

export default router;
