import Vue from "vue";

Vue.mixin({
  methods: {
    $_checkPermission(permissions, module, action) {
      return permissions.some(
        (permission) =>
          permission.permissions.models.name == module &&
          permission.permissions.name == action
      );
    },

    $_getHistory(histories, type, type2 = null) {
      return histories
        ? histories.filter(
            (history) =>
              history.type == type || (type2 && history.type == type2)
          )
        : [];
    },

    $_formatTimestamp(timestamp) {
      const date = new Date(timestamp);
      const day = String(date.getUTCDate()).padStart(2, "0");
      const month = String(date.getUTCMonth() + 1).padStart(2, "0");
      const year = date.getUTCFullYear();
      return `${year}-${month}-${day}`;
    },

    $_formatTimestampX(timestamp) {
      const date = new Date(timestamp);
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, "0");
      const day = String(date.getUTCDate()).padStart(2, "0");
      const hours = String(date.getUTCHours()).padStart(2, "0");
      const minutes = String(date.getUTCMinutes()).padStart(2, "0");
      const seconds = String(date.getUTCSeconds()).padStart(2, "0");
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },

    $_calculateAgeAtJoining(joiningDate, birthDate) {
      if (!joiningDate || !birthDate) return null;
      const ageInMilliseconds = new Date(joiningDate) - new Date(birthDate);
      return Math.floor(ageInMilliseconds / 1000 / 60 / 60 / 24 / 365);
    },

    $_daysDifference(inputDate) {
      const input = new Date(inputDate);
      const current = new Date();
      const differenceInTime = input.getTime() - current.getTime();
      const differenceInDays = Math.round(
        differenceInTime / (1000 * 3600 * 24)
      );
      return differenceInDays;
    },

    $_calculateMeterDiffrence(currentMeter, previousMeter) {
      return currentMeter - previousMeter;
    },
  },
});
